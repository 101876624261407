// import node modules
import React, { Component } from "react";
import { ConsumerAffairsForm } from "@npm/react-component-library";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

class Contact extends Component {
  render() {
    return (
      <Layout
          metaTitle="Contact Us | Gallo Salame®"
          metaDescription="We are proud of our Italian meats and love to hear what our customers think. Contact us today about our products."
      >
        <div className="gagl-contact-us">
          <section className="gagl-contact-wrapper gagl-nav-top-space">
            <header className="gagl-contact-head-container">
              <h1 className="gagl-contact-header ">CONTACT US</h1>
              <p className="gagl-contact-para">
                We’d love to hear from you! Send us a message and let us know your thoughts.
              </p>
            </header>
            <div
              className="gagl-contact-form-wrapper"
              role="img"
              aria-label="galileo storefront"
            >
              <ConsumerAffairsForm
                buttonColor="primary"
                className="gagl-contact-form-container"
                formHandlerApiUri={process.env.GATSBY_FORM_HANDLER_API_URL}
                formHandlerApiKey={process.env.GATSBY_FORM_HANDLER_API_KEY}
              />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Contact;
